import React, { Component } from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Scroll_top_img from "../images/scroll-arrow.png"

import { Helmet } from "react-helmet"


export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>Privacy and Cookie Policy | CueForGood</title>
          <meta name="description"
            content="We are committed to preserving the confidentiality of the personal information we receive about you. The following information is provided to help you understand how we gather, use and protect personal information." />
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=5.0, minimum-scale=1.0" />
          <link rel="canonical" href={this.state.url} />
          <meta property="og:site_name" content="CueForGood" />
          <meta property="fb:app_id" content="289086684439915" />
          <meta property="og:url" content={this.state.url} />
          <meta property="og:title"
            content="Privacy and Cookie Policy | CueForGood" />
          <meta property="og:description"
            content="We are committed to preserving the confidentiality of the personal information we receive about you. The following information is provided to help you understand how we gather, use and protect personal information." />
          <meta property="og:image" content="https://www.CueForGood.com/images/cb.jpg" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description"
            content="We are committed to preserving the confidentiality of the personal information we receive about you. The following information is provided to help you understand how we gather, use and protect personal information." />
          <meta name="twitter:title"
            content="Privacy and Cookie Policy | CueForGood" />
          <meta name="twitter:site" content="@CueForGood" />
          <meta name="twitter:image" content="https://www.CueForGood.com/images/cb.jpg" />
        </Helmet>
        <section className="ser_sec_row privacy-page ">
          <div className="container">
            <div className="main-heading">
              <h2 className="heading_main">PRIVACY POLICY</h2>
              <p>Thank you for visiting the CueForGood website!!</p>
              <p>If you use the CueForGood website, you are accepting the terms and conditions of this Privacy Policy. If you do not agree with the Privacy Policy,
                you must discontinue use of this website.</p>
              <p>From time to time we may modify our security and website procedures without notice.</p>
              <p>
                We are committed to preserving the confidentiality of the personal information we receive about you. The following information is provided to help you understand how we gather, use and protect personal information.
              </p>

              <h3>The Information We Collect</h3>

              <p>You typically provide personal information when communicating with us or other persons, in writing, by phone or electronically. Examples of this information include:</p>

              <p>Name
                <br /> Company name
                <br /> Address
                <br /> Email Id.
                <br /> Phone number
                <br /> Web address
                <br /> Descriptions of business issues</p>

              <br />

              <h3>The Way We Use Information</h3>

              <p>We use the information you provide about yourself and your business to reply to your enquiries.</p>

              <p>We use return email addresses to answer the email we receive. Such addresses are not used for any other purpose and are not shared with outside parties.</p>

              <p>We use non-identifying and aggregate information to better design our website. For example, we track how frequently individual pages are visited as an indicator of interest levels in different service areas.</p>

              <p>We use aggregate data obtained through our free tools to research and compare search engines.</p>

              <p>We use security measures reasonably designed to protect the information we obtain about you. These include: Third parties performing services for us and having access to your information are required to maintain the confidentiality of the information.</p>
              <p>We restrict access to your information in our own offices to specific individuals who have a need to use that information in connection with their employment, and those individuals are subject to an internal confidentiality policy.</p>

              <br />

              <h3>Information about the CueForGood Web Site</h3>

              <p>In general, you may visit our website without telling us who you are or revealing any personally identifiable information about yourself. Our servers do, however, collect a variety of technical data, which we store in aggregate form. This data includes (but is not limited to) your IP address (a unique number that identifies your access account on the Internet), domain, and web browser information. We may track the page you visited before coming to CueForGood.com, the page you link to when you leave CueForGood.com, which of our pages you access, and how long you spend on each page. We use this information to assess site usage, and to help us make improvements to its design and content.</p>

              <p>CueForGood uses Traffic Log Analyzers to analyze traffic to this site.</p>

              <p>At times, we conduct on–line surveys to better understand the needs and profile of our visitors. When we conduct a survey, we will let you know how we will use the information at the time of its collection.</p>
              <br />

              <h3>Security</h3>

              <p>This site has implemented security measures to protect against the loss, misuse and alteration of the information under our control. While we believe these measures to be sufficient, due to the nature of the Internet, we cannot guarantee that no unauthorized access to our servers will occur.</p>

              <br />

              <h3>Links</h3>

              <p>Our website may contain links to other sites on the Internet. Please remember when you click on one of those links you are leaving the CueForGood website and entering another website. We are not responsible for the security, privacy practices, or content of these other websites. If you click on a third party link, the privacy policies of that website will govern the use of your information by that website´s operator. We assume no responsibility or liability for the actions of such third parties with respect to their use of your information or otherwise.</p>

              <br />

              <h3>Cookies</h3>

              <p>A "cookie" is a small piece of information placed on your computer by the website you are visiting online. It allows the site to recognize your computer during the session, or when you visit the site again.</p>

              <p>CueForGood uses temporary cookies, which store a session identifier to facilitate the consumer´s use of the website. We will not collect any information from your computer or leave any retrievable information on your computer using this type of cookie.</p>

              <p>We use cookies and similar tracking technologies to enhance user experience, analyze website traffic, and improve our services. By continuing to browse our website, you consent to the use of these cookies.</p>
              
              <br />
              
              <h3>Types of Cookies We Use</h3>
              
              <ul>
                <li><strong>Essential Cookies –</strong> These are necessary for the website to function properly and cannot be disabled.</li>
                <li><strong>Analytics & Performance Cookies –</strong> Help us understand user interactions and optimize performance.</li>
                <li><strong>Marketing & Advertising Cookies –</strong> Used to deliver relevant ads and track engagement across platforms.</li>
              </ul>
              <br />

              <h3>Tracking Technologies We Utilize</h3>

              <p>We employ third-party tools to improve functionality and measure website performance, including:</p>

              <ul>
                
                <li>Google Tag Manager (GTM)</li>
                <li>Pinterest</li>
                <li>DoubleClick</li>
                <li>Google</li>
                <li>LinkedIn</li>
                <li>Facebook</li>
                <li>Matomo</li>
              
              </ul>

              <p>These services may collect and process data based on their respective privacy policies. You can manage or disable cookies through your browser settings.</p>

              <br />
              
              <h3>Choice/Opt–Out</h3>

              <p>CueForGood.com provides all users with the opportunity to modify or opt–out of receiving non–essential (promotional, marketing–related) communications from us on behalf of our partners, and from us in general, after subscribing to our Newsletter.</p>

              <br />

              <h3>Sharing of Information</h3>

              <p>We may disclose personal information if permitted to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process.</p>

              <p>We may also share some or all of your personal information with:</p>

              <ul>
                <li>Law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</li>
                <li>Another business entity should we (or our assets) plan to merge with, or be acquired by that business entity. Should such a transaction occur, that other business entity (or the new combined entity) will be required to follow this privacy policy with respect to your personal information.</li>
              </ul>

              <p>Enquiries and requests relating to this information should be sent by E–mail to <span>webmaster@cueforgood.com</span> or to our mailing address:</p>

              <p>CueForGood Solutions Pvt. Ltd.<br />
                Plot 1075 A, Third Floor,<br />
                Sector 82, JLPL Industrial Area, <br />
                Sahibzada Ajit Singh (S.A.S) Nagar, Punjab - 160055 <br />
                India <br />
                +91–987-6008994</p>

            </div>

          </div>
        </section>
        <div id="scroll-to" className="case-scroll">
          <a href="#" title="Scroll To Top"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
        </div>
        <Clutch />
      </Layout>
    )

  }
}

